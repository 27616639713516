import { observable, decorate } from 'mobx';
import { Param as param, Validator } from 'dpt-react/models';
import moment from 'moment';

class Measurement {
  constructor(data) {
    this.validate = new Validator();
    this.journal_id = param(data, 'journal_id');
    this.entry_id = param(data, 'entry_id');
    this.revision = param(data, 'revision');
    this.immutable = param(data, 'immutable');
    this.device_id = param(data, 'device_id');
    this.value = param(data, 'value');
    this.start = "";
    this.stop = "";

    if (this.value.type === 'SUBSURFACE') {
      const sortedMeasurements = this.value.subsurface.samples.sort((a, b) => {
        const aTime = moment(a.time).unix()
        const bTime = moment(a.time).unix()
        return aTime > bTime ? 1 : aTime < bTime ? -1 : 0
      })


      if (sortedMeasurements.length > 1) {
        this.start = moment(sortedMeasurements[0].time).format('DD/MM/YYYY - HH:mm')
        this.stop = moment(sortedMeasurements[sortedMeasurements.length-1].time).format('DD/MM/YYYY - HH:mm')
      }
    }

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      journal_id: this.journal_id,
      entry_id: this.entry_id,
      revision: this.revision,
      immutable: this.immutable,
      device_id: this.device_id,
      value: this.value,
    }
  }
}

decorate(Measurement, {
  journal_id: observable,
  entry_id: observable,
  revision: observable,
  immutable: observable,
  device_id: observable,
  value: observable,
  start: observable,
  stop: observable,
})

export default Measurement

