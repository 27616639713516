import React from 'react';
import { Modal, Button } from 'dpt-react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage, Messages } from 'dpt-react/i18n';
import { closeModal, handleApiError } from 'dpt-react/utils';
import { JournalForm } from '../forms';
import { useStores } from 'dpt-react/hooks';

const AddJournalModal = () => {
  const { journalStore } = useStores();

  const close = () => {
    closeModal('addJournal');
  };

  const addJournal = async () => {
    try {
      await journalStore.addJournal();
      Messages.success('added_new_journal');
      close();
    } catch (err) {
      handleApiError(err, 'failed_to_add_new_journal')
    }
  };

  return useObserver(() => {
    const { newJournal } = journalStore;

    return (
      <Modal id="addJournal">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="add_journal" />
          </h1>
          <i className="fas fa-times" onClick={close}/>
        </div>
        <div className="modal-content">
          <JournalForm entity={newJournal} />
        </div>
        <div className="modal-footer">
          <Button text="add_journal" onClick={addJournal}/>
          <p onClick={close}>
            <FormattedMessage id="cancel" />
          </p>
        </div>
      </Modal>
    )
  })
};

export default AddJournalModal;
