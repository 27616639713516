import React, { useState } from 'react';
import { useObserver } from "mobx-react-lite";
import { Modal, Button } from 'dpt-react';
import { FormattedMessage, Messages } from 'dpt-react/i18n';
import { useStores } from 'dpt-react/hooks';
import { closeModal } from 'dpt-react/utils';
import { Journal } from '../partials';

import styles from '../../assets/styles/journals.module.scss';

const AddVesselAccessModal = () => {
  const { journalStore, platformStore } = useStores();
  const [ journalIds, setJournalIds ] = useState([]);

  const close = () => {
    closeModal('addVesselAccess');
  };

  const toggleJournal = journal => {
    if (journalIds.includes(journal.journal_id)) {
      setJournalIds(journalIds.filter(journalId => journal.journal_id !== journalId));
    } else {
      setJournalIds([...journalIds, journal.journal_id]);
    }
  };

  const addJournals = async () => {
    const { selectedPlatform } = platformStore;

    try {
      journalIds.forEach(journalId => {
        selectedPlatform.addVesselAccess(journalId);
      });

      await selectedPlatform.updatePlatform();
      Messages.success('added_vessels');

      close();
    } catch (err) {
      console.log(err);
    }
  };

  return useObserver(() => {
    const { journals } = journalStore;
    const { selectedPlatform } = platformStore;

    const allowedJournalIds = selectedPlatform.access.reduce((allowedIds, accessEntry) => {
      allowedIds.push(accessEntry.journal_id);
      return allowedIds;
    }, [])

    return (
      <Modal id="addVesselAccess">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="allow_to_platform" values={{ platform: selectedPlatform.name }} />
            <i className="fas fa-times" onClick={close} />
          </h1>
        </div>

        <div className="modal-content">
          <ul className={styles.journals}>
            { 
              journals.filter(journal => !allowedJournalIds.includes(journal.journal_id)).map(journal => {
                return (
                  <Journal 
                    key={journal.journal_id}
                    journal={journal}
                    className={journalIds.includes(journal.journal_id) ? styles.active : undefined}
                    onClick={() => toggleJournal(journal)}
                  />
                )
              })
            }
          </ul>
        </div>

        <div className="modal-footer">
          <Button text="update_access" onClick={addJournals} />
          <p onClick={close}>
            <FormattedMessage id="cancel" />
          </p>
        </div>
      </Modal>
    )
  })
};

export default AddVesselAccessModal;
