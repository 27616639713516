import { Param as param, ValidatorV2 as validate } from 'dpt-react/models';

class AccessEntry {
  constructor(data) {
    this.journal_id = param(data, 'journal_id');
    this.device_ids = param(data, 'device_ids', []);

    this.validation = {};

    validate.initializeValidation(this);
  }

  toJSON = () => {
    return {
      journal_id: this.journal_id,
      device_ids: this.device_ids,
    }
  }
}

export default AccessEntry;
