import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import {
  Dashboard,
  Journals,
  Settings,
  Platforms,
  JournalDetails,
  EquipmentDetails,
  DeviceDetails,
  PlatformDeviceDetails,
  PlatformAccess,
  Map
} from "./pages";
import { Header, Aside, Footer, MobileAside } from "./global";
import { useWidthValidator } from "dpt-react/hooks";
import { useStores } from "dpt-react/hooks";
import { useObserver } from "mobx-react-lite";

const Router = () => {
  const { isMobile } = useWidthValidator();
  const { global } = useStores();

  return useObserver(() => {
    // this is a tmp hack to enabled hot reloading for locales
    const { lang } = global;

    return (
      <BrowserRouter>
        {!isMobile && (
          <>
            <Aside />
            <Header />
          </>
        )}

        {isMobile && <MobileAside />}

        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />

          <Route
            exact
            path="/journals/:journalId/equipment/:equipmentId"
            component={EquipmentDetails}
          />

          <Route
            exact
            path="/journals/:journalId/devices/:deviceId"
            component={DeviceDetails}
          />

          <Route exact path="/journals/:journalId" component={JournalDetails} />
          <Route exact path="/journals" component={Journals} />
          <Route exact path="/:platformId/platformAccess" component={PlatformAccess} />
          <Route exact path="/platforms" component={Platforms} />
          <Route exact path="/map" component={Map} />

          <Route
            exact
            path="/platforms/:id/devices"
            component={PlatformDeviceDetails}
          />
          <Route exact path="/settings" component={Settings} />

          <Route
            exact
            path="*"
            component={() => {
              return <Redirect to="/dashboard" />;
            }}
          />
        </Switch>

        {isMobile && <Footer />}
      </BrowserRouter>
    );
  });
};

export default Router;
