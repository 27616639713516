import { observable, decorate } from 'mobx';
import { Param as param, ValidatorV2 as validate } from 'dpt-react/models';

class Vessel {
  constructor(data) {
    this.name = param(data, 'name');
    this.flag_state = param(data, 'flag_state');
    this.cfr = param(data, 'cfr');
    this.hull_number = param(data, 'hull_number');

    this.call_sign = data?.call_sign;
    this.gbr_rss = data?.gbr_rss;
    this.iccat = data?.iccat;
    this.gfcm = data?.gfcm;
    this.uvi = data?.uvi;
    this.imo_code = data?.imo_code;
    this.net_tonnage = data?.net_tonnage;
    this.gross_tonnage = data?.gross_tonnage;
    this.registration_date = data?.registration_date;
    this.full_length = data?.full_length;

    this.validation = {
      name: validate.input('enter_a_valid_name'),
    };

    validate.initializeValidation(this);
  }

  toJSON = () => {
    return {
      name: this.name,
      flag_state: this.flag_state,
      cfr: this.cfr,
      hull_number: this.hull_number,
      call_sign: this.call_sign,
      gbr_rss: this.gbr_rss,
      iccat: this.iccat,
      gfcm: this.gfcm,
      uvi: this.uvi,
      imo_code: this.imo_code,
      net_tonnage: this.net_tonnage,
      gross_tonnage: this.gross_tonnage,
      registration_date: this.registration_date,
      full_length: this.full_length,
    }
  }
}

decorate(Vessel, {
  name: observable,
  flag_state: observable,
  cfr: observable,
  hull_number: observable,
  call_sign: observable,
  gbr_rss: observable,
  iccat: observable,
  gfcm: observable,
  uvi: observable,
  imo_code: observable,
  net_tonnage: observable,
  gross_tonnage: observable,
  registration_date: observable,
  full_length: observable,
});

export default Vessel;

