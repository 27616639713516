import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { FormattedMessage } from "dpt-react/i18n";
import { useRedirect, useStores } from "dpt-react/hooks";

import styles from "../../assets/styles/dashboard.module.scss";

const Dashboard = () => {
  const { journalStore, platformStore } = useStores();
  const { redirect } = useRedirect();

  useEffect(() => {
    journalStore.getAllJournals();
    platformStore.getAllPlatforms();
  }, []);

  return useObserver(() => {
    const { platforms } = platformStore;
    const { journals } = journalStore;
    return (
      <main>
        <h1>
          <FormattedMessage id="dashboard" />
        </h1>

        <div className={styles.cards}>
          <div className={styles.card} onClick={() => redirect("/journals")}>
            <div className={styles.info}>
              <i className="fas fa-anchor" />
              <strong> {journals.length}</strong>
            </div>
            <span>
              <FormattedMessage id="vessels" />
            </span>
          </div>

          <div className={styles.card} onClick={() => redirect("/platforms")}>
            <div className={styles.info}>
              <i className="fas fa-globe" />
              <strong>{platforms.length}</strong>
            </div>
            <span>
              <FormattedMessage id="platforms" />
            </span>
          </div>
        </div>
      </main>
    );
  });
};

export default Dashboard;
