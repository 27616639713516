import React, { useEffect } from 'react';
import { useObserver } from "mobx-react-lite";
import { useParams } from 'react-router-dom';

import { useStores } from 'dpt-react/hooks';
import { FormattedMessage } from 'dpt-react/i18n';
import { openModal } from 'dpt-react/utils';
import { Messages } from 'dpt-react/i18n';

import { AddVesselAccessModal, EditDeviceAccessModal } from '../modals';
import { Journal } from '../partials';

import no_results from '../../assets/images/icons/no_results.svg';
import styles from '../../assets/styles/journals.module.scss'

const PlatformAccess = () => {
  const { journalStore, platformStore } = useStores();
  const { platformId } = useParams();

  useEffect(() => {
    journalStore.getAllJournals();

    if (platformId !== undefined) {
      platformStore.getPlatformById(platformId)
    }
  }, []);

  const addPlatforms = () => {
    openModal('addVesselAccess');
  };

  const editDeviceAccess = (ev, journal) => {
    ev.stopPropagation();
    journalStore.selectedJournal = journal;
    openModal('deviceAccessModal');
  };

  const removeJournalAccess = async (ev, journal) => {
    const { selectedPlatform } = platformStore;
    ev.stopPropagation();
    await selectedPlatform.removeJournalAccess(journal);
    await selectedPlatform.updatePlatform();
    Messages.success('updated_platform');
  };

  return useObserver(() => {
    const { journals } = journalStore;
    const { selectedPlatform } = platformStore;

    const allowedJournalIds = selectedPlatform.access.reduce((allowedIds, accessEntry) => {
      allowedIds.push(accessEntry.journal_id);
      return allowedIds;
    }, [])

    const allowedJournals = journals.filter(journal => allowedJournalIds.includes(journal.journal_id));

    return (
      <main>

        <AddVesselAccessModal />
        <EditDeviceAccessModal />

        <h1 className={styles.title}>
          <div className={styles.info}>
            <span>{selectedPlatform.name}</span>
            <FormattedMessage id="allowed_vessels" />
          </div>

          <div className="cta" onClick={addPlatforms}>
            <i className="fas fa-plus" />
          </div>
        </h1>


        {
          allowedJournals.length === 0 && (
            <div className="placeholder">
              <div className="imgContainer">
                <img src={no_results} alt="no_results" />
              </div>
              <FormattedMessage id="there_are_no_allowed_vessels" />
            </div> 
          )
        }

        <ul className={styles.journals}>
          {
            allowedJournals.map(journal => (
              <Journal 
                journal={journal}
                key={journal.journal_id} 
                className={styles.journalWithIcons}
                Icons={() => (
                  <div className={styles.icons}>
                    <i className="fas fa-lock" onClick={ev => editDeviceAccess(ev, journal)} />
                    <i className="fas fa-trash" onClick={ev => removeJournalAccess(ev, journal)} />
                  </div>
                )}
              /> 
            ))
          }
        </ul>
      </main>
    )
  })
};

export default PlatformAccess;
