import { observable, decorate } from "mobx";
import { Param as param, ValidatorV2 as validate } from "dpt-react/models";
import { controlOnErrors, doFetch, doFetchV2 } from "dpt-react/utils";
import Endpoint from "./Endpoint";
import Device from "./Device.js";
import AccessEntry from './AccessEntry';
import { validateStatusCode } from '../../utils';

const URLS = {
  GET_ALL_PLATFORMS: "/api/v1/platforms",
  GET_PLATFORM_BY_ID: (id) => `/api/v1/platforms/${id}`,
  UPDATE_PLATFORM: (id) => `/api/v1/platforms/${id}`,
  DELETE_PLATFORM: (id) => `/api/v1/platforms/${id}`,
  GET_DEVICES_FROM_PLATFORM: id => `/api/v1/platforms/${id}/devices`,
  ADD_PLATFORM: "/api/v1/platforms",
};

const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const generateApiKey = () => {
  let result = ' ';
  const charactersLength = characters.length;
  for ( let i = 0; i < 128; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

class Platform {
  constructor(data) {
    this.name = param(data, "name");
    this.platform_id = data?.platform_id;
    this.api_key = param(data, 'api_key', generateApiKey());
    this.device_count = param(data, "device_count");
    this.devices = param(data, 'devices', [], Device);
    this.active = param(data, 'active', true);
    this.access = param(data, 'access', [], AccessEntry);
    this.http_endpoint = new Endpoint(data?.http_endpoint || {});
    this.journalIdToAdd = undefined;

    this.device_count = 0;

    const uniqueDevices = [];

    this.access.forEach(journal => {
      journal.device_ids.forEach(did => {
        if (!uniqueDevices.includes(did)) {
          uniqueDevices.push(did)
        }
      })
    });

    this.device_count = uniqueDevices.length;

    this.validation = {
      name: validate.input("enter_a_valid_name"),
    };

    validate.initializeValidation(this);
  }

  static getAllPlatforms = async () => {
    const { data: platforms, status } = await doFetchV2(URLS.GET_ALL_PLATFORMS, "GET");
    validateStatusCode(status);
    return platforms.map((platform) => new Platform(platform));
  };

  static getPlatformById = async (id) => {
    const platform = await doFetch(URLS.GET_PLATFORM_BY_ID(id), "GET");
    return new Platform(platform);
  };

  toggleDeviceAccess = (journalId, deviceId) => {
    const matchingEntry = this.access.find(accessEntry => accessEntry.journal_id === journalId);

    if (matchingEntry !== undefined) {
      if (matchingEntry.device_ids.includes(deviceId)) {
        matchingEntry.device_ids = matchingEntry.device_ids.filter(devId => devId !== deviceId);
        return;
      }

      matchingEntry.device_ids.push(deviceId);
      return;
    }

    this.access.push({
      journal_id: journalId,
      device_ids: [deviceId]
    })
  };

  addVesselAccess = (journalId = this.journalIdToAdd) => {
    this.access.push({
      journal_id: journalId,
      device_ids: []
    })

    this.journalIdToAdd = undefined;
  }

  removeJournalAccess = journal => {
    this.access = this.access.filter(accessEntry => accessEntry.journal_id !== journal.journal_id);
  }

  hasDeviceAccess = (journalId, deviceId) => {
    const matchingEntry = this.access.find(accessEntry => accessEntry.journal_id === journalId);

    if (matchingEntry === undefined) {
      return false;
    }

    return matchingEntry.device_ids.includes(deviceId);
  };

  getDetails = async () => {
    const platformDetails = await doFetch(URLS.GET_PLATFORM_BY_ID(this.platform_id), "GET");
    this.name = param(platformDetails, "name");
    this.http_endpoint = param(platformDetails, "http_endpoint", Endpoint);
  };

  getDevicesFromPlaform = async () => {
    const devices = await doFetch(URLS.GET_DEVICES_FROM_PLATFORM(this.platform_id), 'GET');
    this.devices = devices.map(device => new Device(device));
  };

  updatePlatform = async () => {
    await controlOnErrors(this);
    return doFetch(URLS.UPDATE_PLATFORM(this.platform_id), "PUT", this.toJSON());
  };

  addPlatform = async () => {
    await controlOnErrors(this);
    const platform = await doFetch(URLS.ADD_PLATFORM, "POST", this.toJSON());
    return new Platform(platform);
  };

  deletePlatform = () => {
    return doFetch(URLS.DELETE_PLATFORM(this.platform_id), "DELETE");
  };

  toJSON = () => {
    return {
      name: this.name,
      platform_id: this.platform_id,
      api_key: this.api_key,
      active: this.active,
      http_endpoint: this.http_endpoint.toJSON(),
      access: this.access
    };
  };
}

decorate(Platform, {
  name: observable,
  platform_id: observable,
  http_endpoint: observable,
  device_count: observable,
  devices: observable,
  access: observable,
  journalIdToAdd: observable
});

export default Platform;
