import React from 'react';
import { useObserver } from 'mobx-react-lite';
import styles from "../../assets/styles/platforms.module.scss";

const Platform = ({ platform, onClick, icon, onIconClick }) => {
  return useObserver(() => {
    const uniquePlatforms = platform.access.reduce((arr, p) => {
      if (!arr.includes(p.journal_id)) {
        arr.push(p.journal_id)
      }

      return arr
    }, []);

    return (
      <li
        className={`collection-item ${styles.platform}`}
        onClick={onClick}
      >
        <div className={styles.info}>
          <span>{platform.name}</span>
          <strong>{uniquePlatforms.length} Vessels</strong>
          <strong>{platform.device_count} Devices</strong>
        </div>

        {
          icon && (
            <i className={icon} onClick={onIconClick} />
          )
        }
      </li>
    )
  })
};

export default Platform;
