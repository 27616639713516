import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { FormattedMessage } from "dpt-react/i18n";
import { Label, Spinner } from "dpt-react";
import { useRedirect, useStores } from "dpt-react/hooks";
import { useParams } from "react-router-dom";
import { openModal } from "dpt-react/utils";
import { EditPlatforms } from "../modals";

import no_results from '../../assets/images/icons/no_results.svg';
import styles from "../../assets/styles/equipmentDetails.module.scss";

const EquipmentDetails = () => { const { journalStore } = useStores();
  const { journalId, equipmentId } = useParams();
  const { redirect } = useRedirect();

  useEffect(() => {
    const getData = async () => {
      if (journalId !== undefined) {
        const journal = await journalStore.getJournalById(journalId);

        const { status } = await journal.getEquipmentInventory();

        if (status === 404) {
          return;
        }

        journalStore.selectedJournal = journal;

        if (equipmentId !== undefined) {
          const selectedEquipment = journal.getEquipmentById(equipmentId);
          journalStore.selectedEquipment = selectedEquipment;
        }
      }
    };

    getData();
  }, []);

  const openEditPlatforms = (ev, device) => {
    ev.stopPropagation();
    journalStore.selectedDevice = device;
    openModal("editDevicePlatforms");
  };

  const redirectToDevice = (device) => {
    journalStore.selectedDevice = device;
    redirect(`/journals/${journalId}/devices/${device.device_id}`);
  };

  return useObserver(() => {
    const { selectedEquipment, selectedJournal, loading } = journalStore;
    const { name, devices, installer, maintainer } = selectedEquipment;

    return (
      <main>
        <EditPlatforms />

      {
        loading && (
        <Spinner />
      )
      }

      {
        !loading && (
          <>
            <h1 className={styles.title}>
              <span>
                { name }
              </span>
              <span>
                {selectedJournal.vessel.name}
              </span>
            </h1>


            <h2>
              <FormattedMessage id="devices" />
            </h2>

          {
            devices.length === 0 && (
              <div className="placeholder">
                <div className="imgContainer">
                  <img src={no_results} alt="no_results" />
                </div>
                <FormattedMessage id="there_are_no_devices_linked_to_this_equipment" />
              </div>
            )
          }

          {
            devices.length > 0 && (
              <ul className={styles.list}>
                {devices.map((device) => (
                  <li
                    key={device.device_id}
                    className={`collection-item ${styles.equipment}`}
                    onClick={() => redirectToDevice(device)}
                  >
                    <div>
                      <Label htmlFor="name" />
                      {device.name}
                    </div>

                    <div>
                      <Label htmlFor="type" />
                      {device.type}
                    </div>

                    <div>
                      <Label htmlFor="brand" />
                      {device.brand}
                    </div>

                    <div>
                      <Label htmlFor="complete_up_to"/>
                      <span>{ device.displayCompleteUpTo }</span>
                    </div>


                    <i className={`fas fa-lock-open ${styles.icon}`} onClick={ev => openEditPlatforms(ev, device)}/>
                  </li>
                ))}
              </ul>
            )
          }


          <h2 className="subtitle">
            <FormattedMessage id="device_information" />
          </h2>

          <div className={styles.grid}>
            <div className={styles.information}>
              <h2>
                <FormattedMessage id="installer" />
              </h2>

              {installer.enabled && <div>Enabled</div>}

              {!installer.enabled && (
                <div>There is no installer assigned to this equipment</div>
              )}
            </div>

            <div className={styles.information}>
              <h2>
                <FormattedMessage id="maintainer" />
              </h2>

              {maintainer.enabled && <div>Enabled</div>}

              {!maintainer.enabled && (
                <div>There is no maintainer assigned to this equipment</div>
              )}
            </div>
          </div>
          </>
        )
      }

      </main>
    );
  });
};

export default EquipmentDetails;
