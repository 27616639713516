import React from "react";
import { Modal, Button } from "dpt-react";
import { useObserver } from "mobx-react-lite";
import { FormattedMessage, Messages } from "dpt-react/i18n";
import { closeModal, handleApiError } from "dpt-react/utils";
import { PlatformForm } from "../forms";
import { useStores } from "dpt-react/hooks";

const AddPlatformModal = () => {
  const { platformStore } = useStores();

  const close = () => {
    closeModal("addPlatform");
  };

  const addPlatform = async () => {
    try {
      await platformStore.addPlatform();
      Messages.success("added_new_platform");
      close();
    } catch (err) {
      handleApiError(err, "failed_to_add_new_platform");
    }
  };

  return useObserver(() => {
    const { newPlatform } = platformStore;

    return (
      <Modal id="addPlatform">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="add_platform" />
          </h1>
          <i className="fas fa-times" onClick={close} />
        </div>
        <div className="modal-content">
          <PlatformForm entity={newPlatform} hideApiKey/>
        </div>
        <div className="modal-footer">
          <Button text="add_platform" onClick={addPlatform} />
          <p onClick={close}>
            <FormattedMessage id="cancel" />
          </p>
        </div>
      </Modal>
    );
  });
};

export default AddPlatformModal;
