import { observable, decorate } from 'mobx';
import { Param as param, ValidatorV2 as validate } from 'dpt-react/models';
import { controlOnErrorsV2 as controlOnErrors, doFetch, doFetchV2 } from 'dpt-react/utils';
import { validateStatusCode } from '../../utils';
import moment from 'moment';

import EquipmentInventory from './EquipmentInventory';
import Vessel from './Vessel';

const URLS = {
  GET_ALL_JOURNALS: '/api/v1/journals',
  GET_JOURNAL_BY_ID: id => `/api/v1/journals/${id}?extended=true`,
  GET_JOURNALS_EQUIPMENT_INNVETORY_BY_ID: id => `/api/v1/journals/${id}/equipment_inventory?extended=true`,
  UPDATE_JOURNAL: id => `/api/v1/journals/${id}`,
  DELETE_JOURNAL: id => `/api/v1/journals/${id}`,
  GET_PLATFORMS_BY_JOURNAL_ID: id => `/api/v1/journals/${id}/platforms`,
  ADD_JOURNAL: `/api/v1/journals`,
};

class Journal {
  constructor(data) {
    this.journal_id = data?.journal_id;
    this.serialno = param(data, 'serialno');
    this.bluecherry_id = data?.bluecherry_id;
    this.ship_id = data?.ship_id;
    this.vessel = param(data, 'vessel', Vessel);
    this.dev_state = param(data, 'dev_state');
    this.equipment_inventory = param(data, 'inventory', EquipmentInventory);
    this.entry_id = param(data, 'entry_id');
    this.positions = param(data, 'positions', []);

    this.last_reception = 0;

    this.validation = {
      vessel: this.vessel.validation,
    };

    validate.initializeValidation(this);
  }

  static getAllJournals = async () => {
    const { data: journals, status } = await doFetchV2(URLS.GET_ALL_JOURNALS, 'GET');
    validateStatusCode(status);
    return journals.map(journal => new Journal(journal));
  };

  static getJournalById = async id => {
    const journal = await doFetch(URLS.GET_JOURNAL_BY_ID(id), 'GET');
    return new Journal(journal);
  };

  getEquipmentInventory = async () => {
    const { data: equipmentInventory, status } = await doFetchV2(URLS.GET_JOURNALS_EQUIPMENT_INNVETORY_BY_ID(this.journal_id))
    this.equipment_inventory = new EquipmentInventory(equipmentInventory);


    let latestUnix = 0;

    this.equipment_inventory.equipment.forEach(equipment => {
      equipment?.devices?.forEach(d => {
        if (moment(d.complete_up_to).unix() > latestUnix) {
          latestUnix = moment(d.complete_up_to).unix()
        }
      })
    })

    if (latestUnix === 0) {
      this.last_reception = ''
    } else {
      this.last_reception = moment(latestUnix * 1000).format('DD/MM/YYYY - HH:mm');
    }

    return {
      equipmentInventory: this.equipment_inventory,
      status
    };
  };

  updateJournal = async () => {
    await controlOnErrors(this);
    return doFetch(URLS.UPDATE_JOURNAL(this.journal_id), 'PUT', this);
  };

  getEquipmentById = equipmentId => {
   return this.equipment_inventory.equipment.find(equipment => equipment.equipment_id === equipmentId);
  };

  addJournal = async () => {
    await controlOnErrors(this);
    const journal = await doFetch(URLS.ADD_JOURNAL, 'POST', this.toJSON());
    return new Journal(journal);
  };

  deleteJournal = async () => {
    await controlOnErrors(this);
    return doFetch(URLS.DELETE_JOURNAL(this.journal_id), 'DELETE', this);
  };

  toJSON = () => {
    return {
      journal_id: this.journal_id,
      serialno: this.serialno,
      bluecherry_id: this.bluecherry_id,
      ship_id: this.ship_id,
      vessel: this.vessel,
    }
  }
}

decorate(Journal, {
  serialno: observable,
  ship_id: observable,
  journal_id: observable,
  vessel: observable,
  bluecherry_id: observable,
  equipment_inventory: observable,
  dev_state: observable,
  last_reception: observable,
  positions: observable,
});

export default Journal;

