import moment from 'moment';
import { observable, decorate } from 'mobx';
import { Param as param, ValidatorV2 as validate } from 'dpt-react/models';
import Device from './Device';
import Company from './Company';

import { doFetch } from 'dpt-react/utils';

const URLS = {
  GET_EQUIPMENT_BY_ID: (journalId, deviceId) => `/api/v1/inventory/${journalId}/equipment/${deviceId}`
}

class Equipment {
  constructor(data) {
    this.equipment_id = param(data, 'equipment_id');
    this.name = param(data, 'name');
    this.type = param(data, 'type');
    this.installer = param(data, 'installer', Company);
    this.supplier = param(data, 'supplier', Company);
    this.maintainer = param(data, 'maintainer', Company);
    
    this.devices = param(data, 'devices', Device, []);

    let latestUnix = 0;

    this.devices.forEach(d => {
      if (moment(d.complete_up_to).unix() > latestUnix) {
        latestUnix = moment(d.complete_up_to).unix()
      }
    })


    if (latestUnix === 0) {
      this.last_reception = '--';
    } else {
      this.last_reception = moment(latestUnix * 1000).format('DD/MM/YYYY - HH:mm');
    }

    this.validation = {};
    validate.initializeValidation(this);
  }

  static getEquipmentById = async (journalId, deviceId) => {
    const equipment = await doFetch(URLS.GET_EQUIPMENT_BY_ID(journalId, deviceId));
    return new Equipment(equipment);
  };

  toJSON = () => {
    return {
      equipment_id: this.equipment_id,
      name: this.name,
      type: this.type,
      installer: this.installer,
      devices: this.devices,
    }
  };
}

decorate(Equipment, {
  equipment_id: observable,
  name: observable,
  type: observable,
  installer: observable,
  maintainer: observable,
  supplier: observable,
  devices: observable,
  last_reception: observable
});

export default Equipment;

