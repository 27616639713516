import { decorate, observable } from "mobx";
import { Device, Equipment, Journal } from "../components/models/";

class JournalStore {
  constructor() {
    this.journals = [];
    this.newJournal = new Journal();
    this.selectedJournal = new Journal();
    this.selectedEquipment = new Equipment();
    this.selectedDevice = new Device();
    this.initalLoaded = false;
    this.loading = true;
  }

  getEquipmentInventoryByJournal = journal => {
    journal.getEquipmentInventory();
  };

  getAllJournals = async () => {
    this.journals = await Journal.getAllJournals();
    this.loading = false;
    this.initalLoaded = true;
  };

  getDeviceById = async (deviceId) => {
    this.selectedDevice = await Device.getDeviceById(deviceId);
  };

  getJournalById = async (id) => {
    this.loading = true;
    this.selectedJournal = await Journal.getJournalById(id);
    this.loading = false;
    return this.selectedJournal;
  };

  updateJournal = (journal) => {
    return journal.updateJournal();
  };

  deleteJournal = async (journal) => {
    await journal.deleteJournal();
    this.journals = this.journals.filter(
      (journalData) => journalData.journal_id !== journal.journal_id
    );
  };

  addJournal = async () => {
    const journal = await this.newJournal.addJournal();
    this.journals.push(journal);
    this.newJournal = new Journal();
  };
}

decorate(JournalStore, {
  journals: observable,
  newJournal: observable,
  selectedJournal: observable,
  selectedDevice: observable,
  selectedEquipment: observable,
  loading: observable,
});

export default new JournalStore();
