import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'dpt-react/i18n';

import styles from '../../assets/styles/footer.module.scss'

const Footer = () => {
  const { pathname } = useLocation();

  const isActive = link => {
    if (
      link === pathname ||
      link === '/journals' && pathname.includes('journal') ||
      link === '/platforms' && pathname.includes('platforms')
    ) {
      return styles.active
    }
  };

  return (
    <footer className={styles.footer}>
      <ul>
        <li className={isActive('/dashboard')}>
          <Link to="/dashboard">
            <i className="fas fa-home" />
            <FormattedMessage id="dashboard" />
          </Link>
        </li>
        <li className={isActive('/journals')}>
          <Link to="/journals">
            <i className="fas fa-anchor" />
            <FormattedMessage id="vessels" />
          </Link>
        </li>
        <li className={isActive('/platforms')}>
          <Link to="/platforms">
            <i className="fas fa-globe" />
            <FormattedMessage id="platforms" />
          </Link>
        </li>
        {/* <li className={isActive('/settings')}> */}
        {/*   <Link to="/settings"> */}
        {/*     <i className="fas fa-cog" /> */}
        {/*     <FormattedMessage id="settings" /> */}
        {/*   </Link> */}
        {/* </li> */}
      </ul>
    </footer>
  )
}

export default Footer;
