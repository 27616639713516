import { observable, decorate } from 'mobx';
import { Param as param } from 'dpt-react/models';
import { Auth } from './';

class Endpoint {
  constructor(data) {
    this.active = param(data, 'active', true);
    this.https = param(data, 'https', false);
    this.url = param(data, 'url');
    this.api_key = param(data, 'api_key');
    this.auth = new Auth(data?.auth);
  }

  toJSON = () => {
    return {
      active: this.active,
      url: this.url,
      https: this.https,
      auth: this.auth.toJSON(),
    }
  }
}

decorate(Endpoint, {
  url: observable,
  https: observable,
  active: observable,
  api_key: observable,
  auth: observable,
});

export default Endpoint;

