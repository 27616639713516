import { observable, decorate } from 'mobx';
import { Param as param, ValidatorV2 as validate } from 'dpt-react/models';

class Company {
  constructor(data) {

    this.id = param(data, 'id');
    this.name = param(data, 'name');
    this.address = param(data, 'address');
    this.contact = param(data, 'contact');
    this.enabled = data !== undefined;

    this.validation = {};
    validate.initializeValidation(this);
  }

  toJSON = () => {
    return {
      id: this.id,
      name: this.name,
      address: this.address,
      contact: this.contact,
    }
  }
}

decorate(Company, {
  id: observable,
  name: observable,
  address: observable,
  contact: observable,
  enabled: observable
});

export default Company


