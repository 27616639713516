import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { Label, Input } from 'dpt-react';
import { FormattedMessage } from 'dpt-react/i18n';
import { useState } from 'react';

const JournalForm = ({ entity }) => {
  const [ extraOptionsVisible, setExtraOptionsVisible ] = useState(false);

  return useObserver(() => {
    return (
      <>
        <div className="form-grid">
          <div>
            <Label htmlFor="serialno"/>
            <Input entity={entity} name="serialno" placeholder="placeholder_vessel_serialno" />
          </div>

          <div>
            <Label htmlFor="name"/>
            <Input entity={entity.vessel} name="name" placeholder="placeholder_vessel_name" />
          </div>

          <div>
            <Label htmlFor="hull_number"/>
            <Input entity={entity.vessel} name="hull_number" placeholder="placeholder_vessel_hull_number" />
          </div>

          <div>
            <Label htmlFor="flag_state"/>
            <Input entity={entity.vessel} name="flag_state" placeholder="placeholder_vessel_flag_state" />
          </div>

          <div>
            <Label htmlFor="cfr"/>
            <Input entity={entity.vessel} name="cfr" placeholder="placeholder_vessel_cfr" />
          </div>
        </div>

        <h2 className="subtitle" onClick={() => setExtraOptionsVisible(!extraOptionsVisible)}>
          <FormattedMessage id="advanced_options" />
          <i className="fas fa-chevron-down" />
        </h2>

        {
          extraOptionsVisible && (
            <div className="form-grid">
              <div>
                <Label htmlFor="call_sign"/>
                <Input entity={entity} name="call_sign" placeholder="placeholder_vessel_call_sign" />
              </div>

              <div>
                <Label htmlFor="gbr_rss"/>
                <Input entity={entity} name="gbr_rss" placeholder="placeholder_vessel_gbr_rss" />
              </div>

              <div>
                <Label htmlFor="iccat"/>
                <Input entity={entity} name="iccat" placeholder="placeholder_vessel_iccat" />
              </div>

              <div>
                <Label htmlFor="gfcm"/>
                <Input entity={entity} name="gfcm" placeholder="placeholder_vessel_gfcm" />
              </div>

              <div>
                <Label htmlFor="uvi"/>
                <Input entity={entity} name="uvi" placeholder="placeholder_vessel_uvi" />
              </div>

              <div>
                <Label htmlFor="imo_code"/>
                <Input entity={entity} name="imo_code" placeholder="placeholder_vessel_imo_code" />
              </div>

              <div>
                <Label htmlFor="net_tonnage"/>
                <Input entity={entity} name="net_tonnage" placeholder="placeholder_vessel_net_tonnage" />
              </div>

              <div>
                <Label htmlFor="gross_tonnage"/>
                <Input entity={entity} name="gross_tonnage" placeholder="placeholder_vessel_gross_tonnage" />
              </div>

              <div>
                <Label htmlFor="registration_date"/>
                <Input entity={entity} name="registration_date" placeholder="placeholder_vessel_registration_date" />
              </div>

              <div>
                <Label htmlFor="full_length"/>
                <Input entity={entity} name="full_length" placeholder="placeholder_vessel_full_length" />
              </div>
            </div> 
          )
        }
      </>
    )
  })
};

export default JournalForm;
