import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';

import styles from '../../assets/styles/settings.module.scss'

const Settings = () => {
  return useObserver(() => {
    return (
      <main>
        <h1>
          <FormattedMessage id="settings" />
        </h1>
      </main>
    )
  })
};

export default Settings;
