import React from 'react';
import { Modal, Button } from 'dpt-react';
import { useObserver } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { FormattedMessage, Messages } from 'dpt-react/i18n';
import { closeModal } from 'dpt-react/utils';
import { useStores } from 'dpt-react/hooks';

import styles from '../../assets/styles/managePlatforms.module.scss';

const EditPlatformModal = () => {
  const { journalStore, platformStore } = useStores();
  const { journalId } = useParams();

  const close = () => {
    closeModal('editDevicePlatforms');
  };

  const onOpen = async () => {
    const { selectedDevice } = journalStore;
    const platforms = await platformStore.getAllPlatforms();
    selectedDevice.setAllowedPlatforms(platforms);
  };

  const updatePlatforms = async () => {
    const { selectedDevice } = journalStore;
    try {
      await selectedDevice.updatePlatforms(journalId);
      Messages.success('updated_platforms');
      close();
    } catch {
      Messages.error('failed_to_update_platforms');
    }
  };

  const togglePlatform = platform => {
    const { selectedDevice } = journalStore;
    selectedDevice.togglePlatform(platform)
  };

  return useObserver(() => {
    const { selectedDevice } = journalStore;
    const { platforms } = platformStore;
    const { platforms: platformIds } = selectedDevice;

    return (
      <Modal id="editDevicePlatforms" onOpen={onOpen}>
        <div className="modal-header">
          <h1>
            <FormattedMessage id="enabled_platforms" />
          </h1>
          <i className="fas fa-times" onClick={close}/>
        </div>
        <div className="modal-content">
          <ul className={styles.platforms}>
            {
              platforms.map(platform => (
                <li 
                  key={platform.platform_id} 
                  className={`collection-item ${styles.platform} ${platformIds.includes(platform.platform_id) && styles.active}`}
                  onClick={() => togglePlatform(platform)}
                >
                  <div>
                    <span>{platform.name}</span>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
        <div className="modal-footer">
          <Button text="update" onClick={updatePlatforms}/>
          <p onClick={close}>
            <FormattedMessage id="cancel" />
          </p>
        </div>
      </Modal>
    )
  })
};

export default EditPlatformModal;
