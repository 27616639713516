import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Label, Spinner } from 'dpt-react';
import { FormattedMessage } from 'dpt-react/i18n';
import { useRedirect, useStores } from 'dpt-react/hooks';
import { useParams } from 'react-router-dom';
import { openModal } from 'dpt-react/utils';
import { EditJournalModal } from '../modals';
import no_results from '../../assets/images/icons/no_results.svg';

import styles from '../../assets/styles/journalDetails.module.scss'

const JournalDetails = () => {
  const { journalStore } = useStores();
  const { redirect } = useRedirect();
  const { journalId } = useParams();
  const [ message, setMessage ] = useState(undefined);

  useEffect(() => {
    const getData = async () => {
      if (journalId !== undefined) {
        const journal = await journalStore.getJournalById(journalId);
        journalStore.selectedJournal = journal;
        const { status } = await journal.getEquipmentInventory();

        if (status === 404) {
          setMessage('sync_required');
        }
      }
    };

    getData();
  }, []);

  const openEditJournal = () => {
    openModal('editJournal');
  };

  const redirectToDevices = equipment => {
    const { selectedJournal } = journalStore;
    const { journal_id } = selectedJournal;
    journalStore.selectedEquipment = equipment;
    redirect(`/journals/${journal_id}/equipment/${equipment.equipment_id}`)
  };

  const display = value => {
    return value === undefined || value === '' ? '---' : value;
  };

  return useObserver(() => {
    const { selectedJournal, loading } = journalStore;
    const { vessel, equipment_inventory } = selectedJournal;
    const { name, hull_number } = vessel;
    const { equipment } = equipment_inventory;

    return (
      <main>
        <EditJournalModal />
        {
          !loading && (
            <>
              <h1>
                {
                  hull_number !== "" && (
                    <>
                      {`${hull_number} - `}
                    </>
                  )
                }

                {name}

                <div className="cta" onClick={openEditJournal}>
                  <i className="fas fa-edit" />
                </div>
              </h1>
            </>
          )
        }

        {
          loading && (
            <Spinner />
          )
        }


        {
          !loading && (
            <>
              <h2>
                Vessel information
              </h2>

              <div className={styles.informationGrid}>
                <div>
                  <Label htmlFor="journal_id"/>
                  <span>{ selectedJournal.journal_id }</span>
                </div>

                <div>
                  <Label htmlFor="serialno"/>
                  <span>{ selectedJournal.serialno }</span>
                </div>

                <div>
                  <Label htmlFor="hull_number"/>
                  <span>{ selectedJournal.vessel.hull_number }</span>
                </div>

                <div>
                  <Label htmlFor="call_sign"/>
                  <span>{ display(selectedJournal.vessel.call_sign) }</span>
                </div>

                <div>
                  <Label htmlFor="gbr_rss"/>
                  <span>{ display(selectedJournal.vessel.gbr_rss) }</span>
                </div>

                <div>
                  <Label htmlFor="iccat"/>
                  <span>{ display(selectedJournal.vessel.iccat) }</span>
                </div>

                <div>
                  <Label htmlFor="gfcm"/>
                  <span>{ display(selectedJournal.vessel.gfcm) }</span>
                </div>

                <div>
                  <Label htmlFor="uvi"/>
                  <span>{ display(selectedJournal.vessel.uvi) }</span>
                </div>

                <div>
                  <Label htmlFor="imo_code"/>
                  <span>{ display(selectedJournal.vessel.imo_code) }</span>
                </div>

                <div>
                  <Label htmlFor="net_tonnage"/>
                  <span>{ display(selectedJournal.vessel.net_tonnage) }</span>
                </div>

                <div>
                  <Label htmlFor="gross_tonnage"/>
                  <span>{ display(selectedJournal.vessel.gross_tonnage) }</span>
                </div>

                <div>
                  <Label htmlFor="registration_date"/>
                  <span>{ display(selectedJournal.vessel.registration_date) }</span>
                </div>

                <div>
                  <Label htmlFor="full_length"/>
                  <span>{ display(selectedJournal.vessel.full_length) }</span>
                </div>

              </div>

              <h2>
                Data transfer information
              </h2>

              <div className={styles.informationGrid}>
                <div>
                  <Label htmlFor="entry_id"/>
                  <span>{ display(selectedJournal.entry_id) }</span>
                </div>

                <div>
                  <Label htmlFor="last_data_reception"/>
                  <span>{ display(selectedJournal.last_reception) }</span>
                </div>
              </div>

            </>
          )
        }

        {
          equipment.length > 0 && (
            <h2>
              <FormattedMessage id="equipment_inventory" />
            </h2>
          )
        }

        {
          equipment.length === 0 && !loading && (
            <div className="placeholder">
              <div className="imgContainer">
                <img src={no_results} alt="no_results" />
              </div>
              <FormattedMessage id="there_is_no_equipment_configured_for_this_vessel_a_resync_is_required" />
            </div> 
          )
        }

        {
          !loading && equipment.length > 0 && (
            <ul>
              {
                equipment.map(obj => (
                  <li key={obj.equipment_id} onClick={() => redirectToDevices(obj)} className={`collection-item ${styles.equipment}`}>
                    <div>
                      <Label htmlFor="name" />
                      {obj.name}
                    </div>

                    <div>
                      <Label htmlFor="type" />
                      {obj.type}
                    </div>

                    <div>
                      <Label htmlFor="devices" />
                      {obj.devices.length} <FormattedMessage id={obj.devices.length === 1 ? 'device' : 'devices'} />
                    </div>

                    <div>
                      <Label htmlFor="last_data_reception" />
                      {obj.last_reception}
                    </div>
                  </li>
                ))
              }
            </ul>
          )
        }
      </main>
    )
  })
};

export default JournalDetails;

