import React, {useEffect} from 'react';
import { useObserver } from "mobx-react-lite"
import Chart from 'chart.js';

const MyChart = ({ data, options, id = 'chart' }) => {
  useEffect(() => {
    new Chart(document.getElementById(id), {
      type: 'line',
      data,
      options
    })
  }, [data, options]);

  return useObserver(() => {
    return (
      <>
        <canvas id={id} style={{height: '400px'}} />
      </>
    )
  });
};

export default MyChart;
