import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";

import { useRedirect, useStores } from "dpt-react/hooks";
import { openModal } from "dpt-react/utils";
import { FormattedMessage } from "dpt-react/i18n";
import { Spinner } from "dpt-react";

import { EditPlatformModal, AddPlatformModal } from "../modals";
import { Platform } from "../partials";

import no_results from '../../assets/images/icons/no_results.svg';
import styles from "../../assets/styles/platforms.module.scss";

const Platforms = () => {
  const { platformStore } = useStores();
  const { redirect } = useRedirect();

  useEffect(() => {
    platformStore.getAllPlatforms();
  }, []);

  const openAddModal = () => {
    openModal("addPlatform");
  };

  const openEditModal = (platform) => {
    platformStore.selectedPlatform = platform;
    openModal("editPlatform");
  };

  const redirectToPlatformAccess = platform => {
    platformStore.selectedPlatform = platform;
    const { selectedPlatform } = platformStore;
    redirect(`/${selectedPlatform.platform_id}/platformAccess`);
  };

  return useObserver(() => {
    const { platforms, loading } = platformStore;

    return (
      <main>
        <AddPlatformModal />
        <EditPlatformModal />

        <h1>
          <FormattedMessage id="platforms" />

          <div className="cta" onClick={openAddModal}>
            <i className="fas fa-plus" />
          </div>
        </h1>

        {
          loading && (
            <Spinner />
          )
        }


        {
          !loading && platforms.length === 0 && (
            <div className="placeholder">
              <div className="imgContainer">
                <img src={no_results} alt="no_results" />
              </div>
              <FormattedMessage id="there_are_platforms" />
            </div> 
          )
        }

        {
          !loading && platforms.length > 0 && (
            <ul className={styles.platforms}>
              {platforms.map((platform) => (
                <Platform 
                  key={platform.platform_id} 
                  platform={platform} 
                  onClick={() => openEditModal(platform)} 
                  icon="fas fa-anchor"
                  onIconClick={() => redirectToPlatformAccess(platform)}
                />
              ))}
            </ul>
          )
        }
      </main>
    );
  });
};

export default Platforms;
