import { observable, decorate } from 'mobx';
import { Param as param, ValidatorV2 as validate } from 'dpt-react/models';
import Equipment from './Equipment';

class EquipmentInventory {
  constructor(data) {
    this.journal_id = param(data, 'journal_id');
    this.entry_id = param(data, 'entry_id');
    this.revision = param(data, 'revision');
    this.immutable = param(data, 'immutable');
    this.entry_type = param(data, 'entry_type');
    this.equipment = param(data, 'equipment', Equipment, []);

    this.validation = {};
    validate.initializeValidation(this);
  }

  getDeviceById = deviceId => {
    const equipmentToUse = this.equipment.find(equipment => {
      const deviceIds = equipment.devices.map(device => device.device_id);
      return deviceIds.includes(deviceId);
    })

    if (equipmentToUse !== undefined) {
      return equipmentToUse.devices.find(device => device.device_id === deviceId);
    }
  }

  toJSON = () => {
    return {
      journal_id: this.journal_id,
      entry_id: this.entry_id,
      revision: this.revision,
      immutable: this.immutable,
      entry_type: this.entry_type,
      equipment: this.equipment,
    }
  };
}

decorate(EquipmentInventory, {
  journal_id: observable,
  entry_id: observable,
  revision: observable,
  immutable: observable,
  entry_type: observable,
  equipment: observable,
});

export default EquipmentInventory;

