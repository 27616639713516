import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import moment from 'moment';

import { FormattedMessage, translate } from "dpt-react/i18n";
import { useStores } from "dpt-react/hooks";
import { Spinner, Input, Label, Button } from "dpt-react";

import { EditPlatforms } from "../modals";
import { Map, Chart } from "../elements";

import styles from "../../assets/styles/deviceDetails.module.scss";

const DeviceDetails = () => {
  const { journalStore, platformStore } = useStores();
  const { deviceId, journalId } = useParams();
  const [loadingData, setLoadingData] = useState(true);
  const [loadingMeasurementData, setLoadingMeasurementData] = useState(false);
  const [device, setDevice] = useState(undefined);

  useEffect(() => {
    const getData = async () => {
      if (journalId !== undefined) {
        const journal = await journalStore.getJournalById(journalId);
        await journal.getEquipmentInventory();
        const platforms = await platformStore.getAllPlatforms();

        if (deviceId !== undefined) {
          const device = await journal.equipment_inventory.getDeviceById(deviceId);
          await device.setAllowedPlatforms(platforms);
          await device.getMeasurements(journalId);
          setLoadingData(false);
          setDevice(device)

          if (device === undefined) {
            throw new Error('The requested device is not found');
          }

          journalStore.selectedDevice = device;
        }
      }
    };

    getData();
    platformStore.getAllPlatforms();
  }, []);

  const downloadFile = (measurement) => {
    const text = JSON.stringify(measurement.toJS())
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([text], { type: `text/json` }));
    a.download = "subsurface.json";
    a.click();
  };

  const fetchMeasurements = () => {
    if (device.timeout !== undefined) {
      clearTimeout(device.timeout);
    }

    const newTimeout = setTimeout(async () => {
      setLoadingMeasurementData(true)
      await device.getMeasurements(journalId);
      setTimeout(undefined);
      setLoadingMeasurementData(false)
    }, 500)

    device.timeout = newTimeout;
  };

  return useObserver(() => {
    const { selectedDevice, selectedJournal } = journalStore;
    const { platforms } = platformStore;

    const {
      name,
      installer,
      maintainer,
      platforms: allowedPlatformIds,
      measurements,
      displayCompleteUpTo
    } = selectedDevice;

    const allowedPlatforms = platforms.filter(platform => allowedPlatformIds.includes(platform.platform_id));

    const mapMeasurements = measurements.filter(measurement => measurement.value.type === 'POSITION');
    const scaleMeasurements = measurements.filter(measurement => measurement.value.type === 'SCALE');
    const trawlMeasurements = measurements.filter(measurement => measurement.value.type === 'TRAWL_TENSION');
    const subsurfaceMeasurements = measurements.filter(measurement => measurement.value.type === 'SUBSURFACE');
    const speedMeasurements = measurements.filter(measurement => measurement.value.type === 'SPEEDLOG');
    const shaftMeasurements = measurements.filter(measurement => measurement.value.type === 'SHAFT');
    const weatherMeasurements = measurements.filter(measurement => measurement.value.type === 'WEATHER');

    let charts = measurements.reduce((charts, m) => {
      if (['POSITION', 'SCALE', 'TRAWL_TENSION', 'SUBSURFACE', 'SPEED', 'SPEEDLOG', 'SHAFT', 'WEATHER'].includes(m.value.type)) {
        return charts
      }

      const match = charts.find(c => c.type === m.value.type);
      if (match !== undefined) {
        match.measurements.push(m)
        return charts
      }

      charts.push({
        type: m.value.type,
        measurements: [m],
      })

      return charts
    }, [])

    if (weatherMeasurements.length > 0) {
      const airPressureMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.air_pressure !== undefined
      });

      if (airPressureMeasurements.length > 0) {
        charts.push({
          type: translate('air_pressure'),
          measurements: airPressureMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'AIR_PRESSURE',
                weather: {
                  value: m.value?.weather?.air_pressure
                }
              }
            }
          }),
        });
      }

      const airTemperatureMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.air_temperature !== undefined
      });

      if (airTemperatureMeasurements.length > 0) {
        charts.push({
          type: translate('air_temperature'),
          measurements: airTemperatureMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'AIR_TEMPERATURE',
                weather: {
                  value: m.value?.weather?.air_temperature
                }
              }
            }
          }),
        })
      }


      const waterTemperatureMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.water_temperature !== undefined
      });

      if (waterTemperatureMeasurements.length > 0) {
        charts.push({
          type: translate('water_temperature'),
          measurements: waterTemperatureMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'WATER_TEMPERATURE',
                weather: {
                  value: m.value?.weather?.water_temperature
                }
              }
            }
          }),
        })
      }


      const relativeHumidityMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.relative_humidity !== undefined
      });

      if (relativeHumidityMeasurements.length > 0) {
        charts.push({
          type: translate('relative_humidity'),
          measurements: relativeHumidityMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'RELATIVE_HUMIDITY',
                weather: {
                  value: m.value?.weather?.relative_humidity
                }
              }
            }
          }),
        })
      }


      const absoluteHumidityMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.absolute_humidity !== undefined
      });

      if (absoluteHumidityMeasurements.length > 0) {
        charts.push({
          type: translate('absolute_humidity'),
          measurements: weatherMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'ABSOLUTE_HUMIDITY',
                weather: {
                  value: m.value?.weather?.absolute_humidity
                }
              }
            }
          }),
        })
      }


      const dewPointMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.dew_point !== undefined
      });

      if (dewPointMeasurements.length > 0) {
        charts.push({
          type: translate('dew_point'),
          measurements: dewPointMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'DEW_POINT',
                weather: {
                  value: m.value?.weather?.dew_point
                }
              }
            }
          }),
        });
      }


      const windDirTrueMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.wind_dir_true !== undefined
      });

      if (windDirTrueMeasurements.length > 0) {
        charts.push({
          type: translate('wind_dir_true'),
          measurements: windDirTrueMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'WIND_DIR_TRUE',
                weather: {
                  value: m.value?.weather?.wind_dir_true
                }
              }
            }
          }),
        });
      }

      const windDirMagneticMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.wind_dir_magnetic !== undefined
      });

      if (windDirMagneticMeasurements.length > 0) {
        charts.push({
          type: translate('wind_dir_magnetic'),
          measurements: windDirMagneticMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'WIND_DIR_MAGNETIC',
                weather: {
                  value: m.value?.weather?.wind_dir_magnetic
                }
              }
            }
          }),
        });
      }

      const windSpeedMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.wind_speed !== undefined
      });

      if (windSpeedMeasurements.length > 0) {
        charts.push({
          type: translate('wind_speed'),
          measurements: weatherMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'WIND_SPEED',
                weather: {
                  value: m.value?.weather?.wind_speed
                }
              }
            }
          }),
        });
      }

      const relativeWindChillTempMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.relative_wind_chill_temp !== undefined
      });


      if (relativeHumidityMeasurements.length > 0) {
        charts.push({
          type: translate('relative_wind_chill_temp'),
          measurements: weatherMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'RELATIVE_WIND_CHILL_TEMP',
                weather: {
                  value: m.value?.weather?.relative_wind_chill_temp
                }
              }
            }
          }),
        });
      }

      const theoreticalWindChillTempMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.theoretical_wind_chill_temp !== undefined
      });

      if (theoreticalWindChillTempMeasurements.length > 0) {
        charts.push({
          type: translate('theoretical_wind_chill_temp'),
          measurements: weatherMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'THEORETICAL_WIND_CHILL_TEMP',
                weather: {
                  value: m.value?.weather?.theoretical_wind_chill_temp
                }
              }
            }
          }),
        });
      }


      const rainIntensityMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.rain_intensity !== undefined
      });

      if (rainIntensityMeasurements.length > 0) {
        charts.push({
          type: translate('rain_intensity'),
          measurements: weatherMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'RAIN_INTENSITY',
                weather: {
                  value: m.value?.weather?.rain_intensity
                }
              }
            }
          }),
        });
      }

      const waveHeightMeasurements = weatherMeasurements.filter(m => {
        return m.value?.weather?.wave_height !== undefined
      });

      if (waveHeightMeasurements.length > 0) {
        charts.push({
          type: translate('wave_height'),
          measurements: weatherMeasurements.map(m => {
            return {
              revision: m.revision,
              value: {
                type: 'WAVE_HEIGHT',
                weather: {
                  value: m.value?.weather?.wave_height
                }
              }
            }
          }),
        })
      }
    }


    if (shaftMeasurements.length > 0) {
      charts.push({
        type: translate('RPM'),
        measurements: shaftMeasurements.map(m => {
          return {
            revision: m.revision,
            value: {
              type: 'RPM',
              shaft: {
                rpm: m.value?.shaft?.rpm
              }
            }
          }
        }),
      })

      charts.push({
        type: translate('torque_n'),
        measurements: shaftMeasurements.map(m => {
          return {
            revision: m.revision,
            value: {
              type: 'TORQUE',
              shaft: {
                torque: m.value?.shaft?.torque
              }
            }
          }
        }),
      })

      charts.push({
        type: translate('power_w'),
        measurements: shaftMeasurements.map(m => {
          return {
            revision: m.revision,
            value: {
              type: 'POWER',
              shaft: {
                power: m.value?.shaft?.power
              }
            }
          }
        }),
      })
    }

    if (speedMeasurements.length > 0) {
      const totalCumulativeWaterMeasurements = speedMeasurements;
      const longitudinalWaterSpeedMeasurements = speedMeasurements;

      charts.push({
        type: translate('longitudinal_water_speed'),
        measurements: longitudinalWaterSpeedMeasurements.map(m => {
          return {
            revision: m.revision,
            value: {
              type: 'LONGITUDINAL_WATER_SPEED',
              speedlog: {
                longitudinal_water_speed: m.value.speedlog.longitudinal_water_speed
              }
            }
          }
        }),
      })

      charts.push({
        type: translate('cumulative_water_distance'),
        measurements: totalCumulativeWaterMeasurements.map(m => {
          return {
            revision: m.revision,
            value: {
              type: 'CUMULATIVE_WATER_DISTANCE',
              speedlog: {
                total_cumulative_water_distance: m.value.speedlog.total_cumulative_water_distance
              }
            }
          }
        }),
      })

    }


    charts = charts.map(c => {
      c.data = {
        labels: c.measurements.map(m => m.revision),
        datasets: [
          {
            label: c.type,
            borderColor: '#FA1571',
            fill: false,
            data: c.measurements.map(m => {
              if (m.value.weather !== undefined) {
                return {
                  y: m.value.weather.value,
                  x: new Date(m.revision)
                }
              }

              switch (m.value.type) {
                case "CUMULATIVE_WATER_DISTANCE":
                  return {
                    y: m.value.speedlog.total_cumulative_water_distance,
                    x: new Date(m.revision)
                  }

                case "LONGITUDINAL_WATER_SPEED":
                  return {
                    y: m.value.speedlog.longitudinal_water_speed,
                    x: new Date(m.revision)
                  }

                case "RPM":
                  return {
                    y: m.value?.shaft?.rpm,
                    x: new Date(m.revision)
                  }

                case "TORQUE":
                  return {
                    y: m.value?.shaft?.torque,
                    x: new Date(m.revision)
                  }

                case "POWER":
                  return {
                    y: m.value?.shaft?.power,
                    x: new Date(m.revision)
                  }


                default:
                  return {
                    y: m.value.numeric.value,
                    x: new Date(m.revision)
                  }
              }
            }),
            borderWidth: 2
          },
        ]
      };

      c.options = {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              tooltipFormat: 'HH:mm'
            },
            scaleLabel: {
              labelString: 'Date'
            }
          }],
          yAxes: [{
            scaleLabel: {
              labelString: 'value'
            }
          }]
        },
        elements: {
          point: {
            radius: 0
          }
        }
      };

      return c
    })

    return (
      <main>
        <div id="container"></div>
        <EditPlatforms />

        <h1 className={styles.title}>
          <span>
            {name}
          </span>
          <span>
            {selectedJournal.vessel.name}
          </span>
        </h1>

        <h2>
          <FormattedMessage id="platforms" />
        </h2>

        {
          !loadingData && allowedPlatforms.length !== 0 && (
            <ul className={styles.platforms}>
              {allowedPlatforms.map((platform) => {
                return (
                  <li key={platform.platform_id} className={`collection-item ${styles.platform}`}>
                    <div>{platform.name}</div>
                  </li>
                );
              })}
            </ul>
          )
        }

        {
          !loadingData && allowedPlatforms.length === 0 && (
            <div className={styles.placeholder}>
              No platforms have access to this device.
            </div>
          )
        }

        {
          (loadingData) && (
            <Spinner />
          )
        }


        <h2 className="subtitle">

          <FormattedMessage id="measurements" />
          &nbsp;
          - (<FormattedMessage id="complete_up_to" /> {displayCompleteUpTo})
        </h2>

        <div className={styles.picker}>
          <div>
            <Label htmlFor="from" />
            <Input entity={selectedDevice} name="start" type="datetime-local" />
          </div>
          <div>
            <Label htmlFor="to" />
            <Input entity={selectedDevice} name="stop" type="datetime-local" />
          </div>

          <Button text="fetch_measurements" onClick={fetchMeasurements} />
        </div>

        {
          (loadingData || loadingMeasurementData) && (
            <Spinner />
          )
        }

        {
          !(loadingData || loadingMeasurementData) && mapMeasurements.length !== 0 && (
            <Map measurements={mapMeasurements} />
          )
        }

        {
          !(loadingData || loadingMeasurementData) && charts.length !== 0 && (
            <>
              {
                charts.map((c, id) => {
                  return (
                    <div className={styles.chartContainer} key={c.type} >
                      <Chart options={c.options} data={c.data} id={`chart-${id}`}/>
                    </div>
                  )
                })
              }
            </>
          )
        }

        {
          !(loadingData || loadingMeasurementData) && subsurfaceMeasurements.length !== 0 && (
            <ul className={styles.scrollList}>
              {
                subsurfaceMeasurements.map(measurement => {
                  return (
                    <li key={measurement.revision} className={styles.scaleRecord}>
                      <div>
                        <span>
                          <FormattedMessage id="time" />
                        </span>
                        <span>
                          {moment(measurement.revision).format('DD/MM/YY HH:mm')}
                        </span>
                      </div>

                      <div>
                        <span>
                          <FormattedMessage id="samples" />
                        </span>
                        <span>
                          {measurement.value.subsurface.samples.length}
                        </span>
                      </div>


                      <div>
                        <span>
                          <FormattedMessage id="starting_from" />
                        </span>
                        <span>
                          {measurement.start}
                        </span>
                      </div>

                      <div>
                        <span>
                          <FormattedMessage id="to" />
                        </span>
                        <span>
                          {measurement.stop}
                        </span>
                      </div>

                      <div>
                        <Button text="download" onClick={() => downloadFile(measurement)} />
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          )
        }

        {
          !(loadingData || loadingMeasurementData) && scaleMeasurements.length !== 0 && (
            <ul className={styles.scrollList}>
              {
                scaleMeasurements.map(measurement => {
                  return (
                    <li key={measurement.revision} className={styles.scaleRecord}>
                      <div>
                        <span>
                          <FormattedMessage id="time" />
                        </span>
                        <span>
                          {moment(measurement.revision).format('DD/MM/YY HH:mm')}
                        </span>

                      </div>

                      <div>
                        <span>
                          <FormattedMessage id="haul_number" />
                        </span>

                        <span>
                          {measurement.value.scale.haul_number}
                        </span>
                      </div>

                      <div>
                        <span>
                          <FormattedMessage id="category" />
                        </span>

                        <span>
                          {measurement.value.scale.category}
                        </span>
                      </div>

                      <div>
                        <span>
                          <FormattedMessage id="product" />
                        </span>

                        <span>
                          {measurement.value.scale.product}
                        </span>
                      </div>

                      <div>
                        <span>
                          <FormattedMessage id="weight" />
                        </span>

                        <span>
                          {parseFloat(measurement.value.scale.weight).toFixed(3)}Kg
                        </span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          )
        }

        {
          !loadingData && trawlMeasurements.length !== 0 && (
            <ul className={styles.scrollList}>
              {
                trawlMeasurements.map(measurement => (
                  <li key={measurement.revision} className={styles.trawlRecord}>
                    <div>
                      <span>
                        <FormattedMessage id="time" />
                      </span>
                      <span>
                        {moment(measurement.revision).format('DD/MM/YY HH:mm')}
                      </span>
                    </div>
                    <div>
                      <span>
                        <FormattedMessage id="port" />
                      </span>

                      <span>
                        {measurement.value.trawl_tension.traction_port}
                        kg
                      </span>
                      <span>
                        {measurement.value.trawl_tension.shooted_length_port}
                        m
                      </span>
                    </div>

                    <div>
                      <span>
                        <FormattedMessage id="center" />
                      </span>
                      <span>
                        {measurement.value.trawl_tension.traction_center}
                        kg
                      </span>
                      <span>
                        {measurement.value.trawl_tension.shooted_length_center}
                        m
                      </span>
                    </div>
                    <div>
                      <span>
                        <FormattedMessage id="starboard" />
                      </span>

                      <span>
                        {measurement.value.trawl_tension.traction_starboard}
                        kg
                      </span>
                      <span>
                        {measurement.value.trawl_tension.shooted_length_starboard}
                        m
                      </span>
                    </div>
                  </li>
                ))
              }
            </ul>
          )
        }

        {
          !loadingData && charts.length === 0 && mapMeasurements.length === 0 && trawlMeasurements.length === 0 && scaleMeasurements.length === 0 && subsurfaceMeasurements.length === 0 && shaftMeasurements.length === 0 && (
            <div className={styles.placeholder}>
              <FormattedMessage id="there_are_currently_no_measurements_for_this_device" />
            </div>
          )
        }


        <h2 className="subtitle">
          <FormattedMessage id="device_information" />
        </h2>

        <div className={styles.grid}>
          <div className={styles.information}>
            <h2>
              <FormattedMessage id="installer" />
            </h2>

            {installer.enabled && <div>Enabled</div>}

            {!installer.enabled && (
              <div>There is no installer assigned to this device</div>
            )}
          </div>

          <div className={styles.information}>
            <h2>
              <FormattedMessage id="maintainer" />
            </h2>

            {maintainer.enabled && <div>Enabled</div>}

            {!maintainer.enabled && (
              <div>There is no maintainer assigned to this device</div>
            )}
          </div>
        </div>
      </main>
    );
  });
};

export default DeviceDetails;
