import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';

import dbmatic_logo from '../../assets/images/dbmatic_white.svg';
import bc_logo from '../../assets/images/logo_cherry_bluecherry_slogan.inv.svg';
import styles from '../../assets/styles/aside.module.scss';

const Aside = () => {
  const { pathname } = useLocation();

  const isActive = link => {
    if (
      link === pathname ||
      link === '/journals' && pathname.includes('journal') ||
      link === '/platforms' && pathname.includes('platform')
    ) {
      return styles.active
    }
  };

  return useObserver(() => {
    return (
      <aside>
        <div className={styles.imgContainer}>
          <img src={dbmatic_logo} alt="DBMatic" />
        </div>

        <ul className={styles.links}>
          <li className={isActive('/dashboard')}>
            <i className="fas fa-home" />
            <Link to="/dashboard">
              <FormattedMessage id="dashboard" />
            </Link>
          </li>

          <li className={isActive('/journals')}>
            <Link to="/journals">
              <i className="fas fa-anchor" />
              <FormattedMessage id="vessels" />
            </Link>
          </li>

          <li className={isActive('/platforms')}>
            <Link to="/platforms">
              <i className="fas fa-globe" />
              <FormattedMessage id="platforms" />
            </Link>
          </li>

          <li className={isActive('/map')}>
            <Link to="/map">
              <i className="fas fa-map" />
              <FormattedMessage id="map" />
            </Link>
          </li>

          {/* <li className={isActive('/settings')}> */}
          {/*   <Link to="/settings"> */}
          {/*     <i className="fas fa-cog" /> */}
          {/*     <FormattedMessage id="settings" /> */}
          {/*   </Link> */}
          {/* </li> */}
        </ul>

        <div className={styles.bcLogo}>
          <img src={bc_logo} alt="BlueCherry IoT connected" />
        </div>
      </aside>
    )
  })
};

export default Aside;
