import { Dropdown } from "dpt-react";
import React from "react";
import styles from "../../assets/styles/header.module.scss";
import { useStores } from 'dpt-react/hooks';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';

const Header = () => {
  const { global } = useStores();

  return useObserver(() => {
    const { lang, languages, logout } = global;

    const currentLanguage = languages.find(language => language.short === lang);

    return (
      <header className={styles.header}>
        <ul>
          <Dropdown text={currentLanguage?.long || lang} icon="fas fa-chevron-down">
            {
              languages.map(language => (
                <li onClick={() => global.updateLanguage(language.short)} key={language.short}>
                  {
                    language.long
                  }
                </li>
              ))
            }
          </Dropdown>

          <Dropdown text="Logout">
            <li onClick={logout}>
              <FormattedMessage id="logout" />
            </li>
          </Dropdown>
        </ul>
      </header>
    );
  });
};

export default Header;
