import React, { useState } from "react";
import { Button, Modal } from "dpt-react";
import { useObserver } from "mobx-react-lite";
import { FormattedMessage, Messages } from "dpt-react/i18n";
import { closeModal, handleApiError } from "dpt-react/utils";
import { useStores } from "dpt-react/hooks";
import { PlatformForm } from "../forms";
// import { ManageVesselAccess, ManageDeviceAccess } from "../partials";

import deleteSvg from "../../assets/images/icons/delete.svg";
import warningSvg from "../../assets/images/icons/warning.svg";
import editSvg from "../../assets/images/icons/edit.svg";
import lockSvg from "../../assets/images/icons/lock.svg";
import styles from "../../assets/styles/platformModal.module.scss";
import { useRedirect } from "dpt-react/hooks";

const views = {
  OVERVIEW: 'overview',
  DELETE: 'delete',
  EDIT: 'edit',
};

const EditPlatformModal = () => {
  const { platformStore } = useStores();
  const [ loading, setLoading ] = useState(false);
  const { redirect } = useRedirect();
  const [ view, setView ] = useState(views.OVERVIEW);

  const close = () => {
    closeModal("editPlatform");
  };

  const deletePlatform = async () => {
    const { selectedPlatform } = platformStore;
    await platformStore.deletePlatform(selectedPlatform);
    Messages.success("deleted_platform");
    close();
  };

  const onOpen = async () => {
    const { selectedPlatform } = platformStore;
    setLoading(true);
    await selectedPlatform.getDetails();
    setLoading(false);
  };

  const editPlatform = async () => {
    const { selectedPlatform } = platformStore;
    try {
      await platformStore.updatePlatform(selectedPlatform);
      Messages.success("updated_platform");
      close();
    } catch (err) {
      handleApiError(err, "failed_to_update_new_platform");
    }
  };

  const onClose = () => {
    setView(views.OVERVIEW);
  };

  const redirectToPlatformAccess = () => {
    const { selectedPlatform } = platformStore;
    redirect(`/${selectedPlatform.platform_id}/platformAccess`);
  };

  return useObserver(() => {
    const { selectedPlatform } = platformStore;

    return (
      <Modal id="editPlatform" onOpen={onOpen} onClose={onClose}>
        <div className="modal-header">
          <h1>
            {view === views.OVERVIEW && <FormattedMessage id="choose_your_option" />}
            {view === views.EDIT && <FormattedMessage id="edit_platform" values={{ platform: selectedPlatform.name }} />}
            {view === views.DELETE && <FormattedMessage id="warning" />}
            {view === views.VESSEL_ACCESS && <FormattedMessage id="manage_vessels_access" values={{ platform: selectedPlatform.name }} />}
            {view === views.DEVICE_ACCESS && <FormattedMessage id="device_access" values={{ platform: selectedPlatform.name }} />}
          </h1>
          <i className="fas fa-times" onClick={close} />
        </div>
        <div className="modal-content">
          {view === views.OVERVIEW && (
            <>
              <div className="options tripple">
                <div className="option" onClick={() => setView(views.EDIT)}>
                  <div className="imgContainer">
                    <img src={editSvg} />
                  </div>
                  <FormattedMessage id="edit" />
                </div>
                <div className="option" onClick={() => setView(views.DELETE)}>
                  <div className="imgContainer">
                    <img src={deleteSvg} />
                  </div>
                  <FormattedMessage id="delete" />
                </div>

                <div className="option" onClick={redirectToPlatformAccess}>
                  <div className="imgContainer">
                    <img src={lockSvg} />
                  </div>
                  <FormattedMessage id="manage_vessels" />
                </div>
              </div>
            </>
          )}

          { view === views.EDIT && <PlatformForm entity={selectedPlatform} /> }

          {view === views.DELETE && (
            <div className={styles.deleteContainer}>
              <div className={styles.deleteImgContainer}>
                <img src={warningSvg} />
              </div>
              <FormattedMessage id="are_you_sure_you_want_to_delete_this_platform" />
            </div>
          )}

        </div>
        <div className="modal-footer">
          {view === views.OVERVIEW && (
            <>
              <p className="btn" onClick={close}>
                <FormattedMessage id="cancel" />
              </p>
            </>
          )}
          {view === views.EDIT && (
            <>
              <Button text="update" onClick={editPlatform} />
              <p onClick={() => setView(views.OVERVIEW)}>
                <FormattedMessage id="cancel" />
              </p>
            </>
          )}

          {view === views.DELETE && (
            <>
              <Button text="delete_platform" onClick={deletePlatform} />
              <p onClick={() => setView(views.OVERVIEW)}>
                <FormattedMessage id="cancel" />
              </p>
            </>
          )}
        </div>
      </Modal>
    );
  });
};

export default EditPlatformModal;
