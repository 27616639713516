import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';
import { Spinner } from 'dpt-react';
import { useRedirect, useStores } from 'dpt-react/hooks';
import { openModal } from 'dpt-react/utils';
import { AddJournalModal } from '../modals';
import no_results from '../../assets/images/icons/no_results.svg';

import styles from '../../assets/styles/journals.module.scss'
import {Journal} from '../partials';

const Journals = () => {
  const { journalStore } = useStores();
  const { redirect } = useRedirect();

  useEffect(() => {
    journalStore.getAllJournals();
  }, []);

  const setSelectedJournal = journal => {
    journalStore.selectedJournal = journal;
    redirect(`/journals/${journal.journal_id}`);
  };

  const openAddModal = () => {
    openModal('addJournal');
  };

  return useObserver(() => {
    const { journals, loading } = journalStore;

    return (
      <main>

        <AddJournalModal />

        <h1>
          <FormattedMessage id="vessels" />

          <div className="cta" onClick={openAddModal}>
            <i className="fas fa-plus" />
          </div>
        </h1>

        {
          loading && (
            <Spinner />
          )
        }

        {
          !loading && journals.length === 0 && (
            <div className="placeholder">
              <div className="imgContainer">
                <img src={no_results} alt="no_results" />
              </div>
              <FormattedMessage id="there_are_no_vessels_linked_to_this_account" />
            </div>)
        }

        {
          !loading && journals.length > 0 && (
            <ul className={styles.journals}>
              {
                journals.map(journal => {
                  return <Journal key={journal.journal_id} journal={journal} onClick={() => setSelectedJournal(journal)} />
                })
              }
            </ul>
          )
        }
        
      </main>
    )
  })
};

export default Journals;
