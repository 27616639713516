import { observable, decorate, observe } from 'mobx';
import { Param as param, Validator } from 'dpt-react/models';
import { doFetchV2, doFetch } from 'dpt-react/utils';
import moment from 'moment';
import { Measurement } from '.';

const URLS = {
  GET_DEVICE_BY_ID: id => `/api/v1/devices/${id}`,
  UPDATE_PLATFORM: id => `/api/v1/platforms/set_access/device/${id}`,
  GET_MEASUREMENTS_BETWEEN_TIMES: (journalId, deviceId, start, stop) => `/api/v1/journals/${journalId}/measurements/${deviceId}?start=${start}&end=${stop}`
};

class Device {
  constructor(data) {
    this.validate = new Validator();
    this.device_id = param(data, 'device_id');
    this.name = param(data, 'name');
    this.type = param(data, 'type');
    this.brand = param(data, 'brand');
    this.product_no = param(data, 'product_no');
    this.serial_no = param(data, 'serial_no');
    this.supplier = param(data, 'supplier');
    this.installer = param(data, 'installer');
    this.maintainer = param(data, 'maintainer');
    this.platforms = param(data, 'platforms', []);
    this.journal_id = param(data, 'journal_id');
    this.journal_name = param(data, 'journal_name');
    this.complete_up_to = param(data, 'complete_up_to');

    this.timeout = undefined;


    this.start = moment().subtract(6, 'hour').format('YYYY-MM-DDTHH:mm');
    this.stop = moment().format('YYYY-MM-DDTHH:mm');

    this.measurements = [];

    if (this.complete_up_to === '') {
      this.displayCompleteUpTo = '--';
    } else {
      this.displayCompleteUpTo = moment(this.complete_up_to).format('DD/MM/YYYY - HH:mm')
    }

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }


  static getDeviceById = async (deviceId) => {
    const device = await doFetch(URLS.GET_DEVICE_BY_ID(deviceId), 'GET');
    return new Device(device);
  };

  getMeasurements = async (journalId) => {
    const start = moment(this.start).unix();
    const stop = moment(this.stop).unix();
    const url = URLS.GET_MEASUREMENTS_BETWEEN_TIMES(journalId, this.device_id, start, stop)

    const { data: measurements } = await doFetchV2(url, 'GET');
    this.measurements = measurements.sort((a, b) => {
      if (moment(a.revision).unix() === moment(b.revision).unix()) {
        return 0
      } else if (moment(a.revision).unix() > moment(b.revision).unix()) {
        return -1
      }

      return 1
    }).map(measurement => new Measurement(measurement));
  }

  setAllowedPlatforms = platforms => {
    this.platforms = [];

    platforms.forEach(platform => {
      platform.access.forEach(accessEntry => {
        if (accessEntry.device_ids.includes(this.device_id)) {
          this.platforms.push(platform.platform_id);
        }
      })
    })
  };

  togglePlatform = platform => {
    if (this.platforms.includes(platform.platform_id)) {
      this.platforms = this.platforms.filter(id => id !== platform.platform_id)
    } else {
      this.platforms.push(platform.platform_id);
    }
  };

  updatePlatforms = (journalId) => {
    return doFetch(URLS.UPDATE_PLATFORM(this.device_id), 'PUT', {
      platform_ids: this.platforms,
      journal_id: journalId
    });
  };

  toJSON = () => {
    return {
      device_id: this.device_id,
      name: this.name,
      type: this.type,
      brand: this.brand,
      product_no: this.product_no,
      serial_no: this.serial_no,
      supplier: this.supplier,
      installer: this.installer,
      maintainer: this.maintainer,
    }
  }
}

decorate(Device, {
  device_id: observable,
  name: observable,
  type: observable,
  brand: observable,
  product_no: observable,
  serial_no: observable,
  supplier: observable,
  installer: observable,
  maintainer: observable,
  platforms: observable,
  journal_name: observable,
  journal_id: observable,
  measurements: observable,
  start: observable,
  stop: observable,
  timeout: observable,
});

export default Device

