import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { Label, Input, List, Checkbox } from 'dpt-react';
import { FormattedMessage } from 'dpt-react/i18n';
import styles from '../../assets/styles/platforms.module.scss';
import {useState} from 'react';

const authTypes = [ 
  {
    text: 'AUTH_NONE',
    value: 0,
  },
  {
    text: 'AUTH_BEARER_AUTH_HEADER',
    value: 1
  }
];

const PlatformForm = ({ entity, hideApiKey }) => {
  const [ displayEndpointSettings, setDisplayEndpointSettings ] = useState(false);

  return useObserver(() => {
    return (
      <div>
        <div>
          <Label htmlFor="name"/>
          <Input entity={entity} name="name" placeholder="placeholder_platform_name" />
        </div>

        <div>
          <Label htmlFor="access_api_key"/>
          <input type="text" disabled  value={btoa(entity.api_key)} placeholder="placeholder_platform_api_key" />
        </div>

        <h2 className="subtitle" style={{ cursor: 'pointer' }} onClick={() => setDisplayEndpointSettings(!displayEndpointSettings)}>
          <FormattedMessage id="endpoint_settings" />
          <i className="fas fa-chevron-down" />
        </h2>
        

        {
          displayEndpointSettings && (
            <>
              <div className={styles.checkboxes}>
                <div>
                  <Label htmlFor="active"/>
                  <Checkbox entity={entity} name="http_endpoint.active" />
                </div>

                <div>
                  <Label htmlFor="https"/>
                  <Checkbox entity={entity} name="http_endpoint.https" />
                </div>
              </div>

              <div>
                <Label htmlFor="url"/>
                <Input entity={entity} name="http_endpoint.url" placeholder="placeholder_platform_url" />
              </div>

              <div>
                <Label htmlFor="endpoint_auth_type"/>
                <List entity={entity} name="http_endpoint.auth.type" list={authTypes} text="translation" value="value" />
              </div>

              {
                !hideApiKey  && (
                  <div>
                    <Label htmlFor="endpoint_api_key"/>
                    <textarea value={btoa(entity.api_key)} onChange={() => console.log('do nothing')} />
                  </div>
                )
              }
            </>
          )
        }
      </div>
    )
  })
};

export default PlatformForm;
