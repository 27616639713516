import React, { useEffect } from 'react';

import { useObserver } from "mobx-react-lite";
import { FormattedMessage } from 'dpt-react/i18n';
import { JournalMap } from '../elements/';
import { mapStore } from '../../stores/';

const MapPage = () => {

  useEffect(() => {
    mapStore.getJournalsWithTheirPositions();

    const interval = setInterval(() => {
      mapStore.getJournalsWithTheirPositions();
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return useObserver(() => {
    const { journals } = mapStore;

    return (
      <main>
        <h1>
          <FormattedMessage id="map" />
        </h1>

        <JournalMap journals={journals}/>
      </main>
    )
  })
};

export default MapPage;
