import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { Label } from 'dpt-react';

import styles from '../../assets/styles/journals.module.scss'

const Journal = ({ journal, onClick, className = '', Icons }) => {
  return useObserver(() => {
    const { vessel, dev_state } = journal;
    const { name, flag_state, cfr, hull_number } = vessel;

    return (
      <li key={journal.journal_id} className={`collection-item ${styles.journal} ${className}`} onClick={onClick}>
        <div className={dev_state ? styles.online : styles.offline}/>
        <div>
          <Label htmlFor="name"/>
          <span>{name}</span>
        </div>
        <div>
          <Label htmlFor="flag_state"/>
          <span>{flag_state}</span>
        </div>
        <div>
          <Label htmlFor="cfr"/>
          <span>{cfr === '' ? '--' : cfr}</span>
        </div>
        <div>
          <Label htmlFor="hull_number"/>
          <span>{hull_number === '' ? '--' : hull_number}</span>
        </div>

        {
          Icons && (
            <Icons />
          )
        }
      </li>
    )

  })
};

export default Journal;
