import { observable, decorate } from 'mobx';
import { Param as param } from 'dpt-react/models';

const AuthTypes = {
  AUTH_NONE: 0,
  AUTH_BEARER_AUTH_HEADER: 1,
};

class Auth {
  constructor(data) {
    this.type = param(data, 'type', AuthTypes.AUTH_NONE);
    this.api_key = data?.api_key || '';
  }

  toJSON = () => {
    return {
      type: parseInt(this.type),
      api_key: this.api_key,
    }
  }
}

decorate(Auth, {
  type: observable,
  api_key: observable,
});

export default Auth;
