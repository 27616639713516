import { decorate, observable } from "mobx";
import { openModal } from "dpt-react/utils";

class Global {
  constructor() {
    this.lang = this.loadStoredLanguage();
    this.languages = [
      { short: "nl", long: "Nederlands" },
      { short: "fr", long: "Français" },
      { short: "de", long: "Deutsch" },
      { short: "en", long: "English" },
      { short: "es", long: "Español" },
      { short: "it", long: "Italiano" },
    ];

    if (typeof (Storage) !== undefined) {
      localStorage.setItem("ws_enabled", this.wsEnabled);
    }
  }

  toggleInfo = () => {
    this.displayInfo = !this.displayInfo;
  };

  loadStoredLanguage = () => {
    let languageToLoad = "en";
    const possibleLanguages = ["es", "en", "fr", "de", "it", "nl"];
    const localStorageResult = localStorage.getItem("_site_lang");

    if (
      localStorageResult &&
      possibleLanguages.includes(localStorageResult.toLocaleLowerCase())
    ) {
      languageToLoad = localStorageResult.toLowerCase();
    }

    return languageToLoad;
  };

  updateLanguage = (lang) => {
    this.lang = lang;

    if (typeof (Storage) !== undefined) {
      localStorage.setItem("_site_lang", lang.toLowerCase());
    }
  };

  displayWarningModal = (title, message, buttons) => {
    this.warningTitle = title;
    this.warningMessage = message;
    this.buttons = buttons;

    openModal("warningModal");
  };

  resetCookie = () => {
    const host = new URL(window.location.href).hostname;
    const domain = (host === 'localhost' || (host.match(/\./g) || []).length <= 1)
      ? host
      : host.substr(host.indexOf('.') + 1, host.length);

    const cookieName = 'bc-token';
    const cookieValue = '';
    const myDate = 'Thu, 01 Jan 1970 00:00:01 GMT';

    document.cookie = `${cookieName}=${cookieValue};expires=${myDate};domain=${domain};path=/`;
  };

  logout = () => {
    this.resetCookie();
    window.location.href = "https://sso.dbmatic.eu"
  };
}

decorate(Global, {
  lang: observable,
});

export default new Global();
