import { doFetch } from 'dpt-react/utils';
import { observable, decorate } from 'mobx';
import { Journal } from "../components/models/";

const URLS = {
  GET_JOURNALS_WITH_POSITION: `/api/v1/journals/positions`
}

class MapStore {
  constructor() {
    this.journals = [];
  }

  getJournalsWithTheirPositions = async () => {
    const data = await doFetch(URLS.GET_JOURNALS_WITH_POSITION, 'GET')
    this.journals = data.map(d => {
      const data = {...d.journal, positions: d.positions}
      return new Journal(data)
    });
  }
}

decorate(MapStore, {
  journals: observable,
});


export default new MapStore();
