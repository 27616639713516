import React, { useState } from "react";
import { Modal, Button } from "dpt-react";
import { useObserver } from "mobx-react-lite";
import { FormattedMessage, Messages } from "dpt-react/i18n";
import { closeModal, handleApiError } from "dpt-react/utils";
import { JournalForm } from "../forms";
import { useRedirect, useStores } from "dpt-react/hooks";

import deleteSvg from "../../assets/images/icons/delete.svg";
import warningSvg from "../../assets/images/icons/warning.svg";
import editSvg from "../../assets/images/icons/edit.svg";
import styles from "../../assets/styles/journalDetails.module.scss";

const views = {
  OVERVIEW: "overview",
  DELETE: "delete",
  EDIT: "edit",
};

const EditJournalModal = () => {
  const { journalStore } = useStores();
  const { redirect } = useRedirect();
  const [view, setView] = useState(views.OVERVIEW);

  const close = () => {
    closeModal("editJournal");
  };

  const deleteJournal = async () => {
    const { selectedJournal } = journalStore;
    await journalStore.deleteJournal(selectedJournal);
    Messages.success("deleted_journal");
    close();

    setTimeout(() => {
      redirect('/journals')
    }, 500)
  };

  const editJournal = async () => {
    const { selectedJournal } = journalStore;
    try {
      await journalStore.updateJournal(selectedJournal);
      Messages.success("updated_journal");
      close();
    } catch (err) {
      handleApiError(err, "failed_to_update_new_journal");
    }
  };

  const onClose = () => {
    setView(views.OVERVIEW);
  };

  return useObserver(() => {
    const { selectedJournal } = journalStore;

    return (
      <Modal id="editJournal" onClose={onClose}>
        <div className="modal-header">
          <h1>
            {view === views.OVERVIEW && (
              <FormattedMessage id="choose_your_option" />
            )}
            {view === views.EDIT && <FormattedMessage id="edit_journal" values={{ journal: selectedJournal.vessel.name }}/>}
            {view === views.DELETE && <FormattedMessage id="warning" />}
          </h1>
          <i className="fas fa-times" onClick={close} />
        </div>
        <div className="modal-content">
          {view === views.OVERVIEW && (
            <>
              <div className="options">
                <div className="option" onClick={() => setView(views.EDIT)}>
                  <div className="imgContainer">
                    <img src={editSvg} />
                  </div>
                  <FormattedMessage id="edit" />
                </div>
                <div className="option" onClick={() => setView(views.DELETE)}>
                  <div className="imgContainer">
                    <img src={deleteSvg} />
                  </div>
                  <FormattedMessage id="delete" />
                </div>
              </div>
            </>
          )}

          {view === views.EDIT && <JournalForm entity={selectedJournal} />}

          {view === views.DELETE && (
            <div className={styles.deleteContainer}>
              <div className={styles.deleteImgContainer}>
                <img src={warningSvg} />
              </div>
              <FormattedMessage
                id="are_you_sure_you_want_to_delete_this_journal"
                values={{ name: selectedJournal?.vessel?.name }}
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          {view === views.OVERVIEW && (
            <>
              <p className="btn" onClick={close}>
                <FormattedMessage id="close" />
              </p>
            </>
          )}
          {view === views.EDIT && (
            <>
              <Button text="update" onClick={editJournal} />
              <p onClick={() => setView(views.OVERVIEW)}>
                <FormattedMessage id="cancel" />
              </p>
            </>
          )}

          {view === views.DELETE && (
            <>
              <Button text="delete_journal" onClick={deleteJournal} />
              <p onClick={() => setView(views.OVERVIEW)}>
                <FormattedMessage id="cancel" />
              </p>
            </>
          )}
        </div>
      </Modal>
    );
  });
};

export default EditJournalModal;
