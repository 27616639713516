import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";

import Router from './components/Router';
import reportWebVitals from './reportWebVitals';
import * as stores from './stores';

import { useClearCache } from "react-clear-cache";
import locales from './assets/locales/locales.json';
import { config } from 'dpt-react';

import { ToastContainer, toast } from 'react-toastify';

import '@fortawesome/fontawesome-free/css/all.css'
import 'dpt-react/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/screen.scss';

let App;

if (navigator.userAgent.toLowerCase().includes('firefox')) {
  App = () => {
    config.setLocales(locales);
    config.setStores(stores);
    config.setToast(toast);


    return (
      <Provider {...stores}>
        <>
          <Router />
          <ToastContainer />
        </>
      </Provider>
    )
  }
} else {
  App = () => {
    config.setLocales(locales);
    config.setStores(stores);
    config.setToast(toast);


    return (
      <Provider {...stores}>
        <>
          <Router />
          <ToastContainer />
        </>
      </Provider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
