import React from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import { useObserver } from "mobx-react-lite";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});

const style = {
  width: "100%",
  height: "300px"
};

const CustomMap = ({ measurements }) => {
  const positions = measurements.map(measurement => {
    return {
      latitude: measurement.value.position.latitude,
      longitude: measurement.value.position.longitude,
    }
  });

  let latestPosition = undefined;
  if (measurements.length > 0) {
    latestPosition = [positions[0]?.latitude, positions[0]?.longitude];
  }

  const center = positions.length > 0 ? [positions[0]?.latitude, positions[0]?.longitude] : [53.06545639038086, 2.4597198963165283]

  return useObserver(() => {
    return (
      <MapContainer style={style} center={center} zoom={6} maxZoom="19">

        <TileLayer
          attribution="open"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        />

        <TileLayer
          attribution="open"
          url = "http://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" /> 

        <Polyline positions={positions.map(position => [position.latitude, position.longitude])} attribution="open" color="darkorange"/>

        {
          latestPosition !== undefined && (
            <Marker position={latestPosition}>
            </Marker>
          )
        }

      </MapContainer>
    )
  })
}

export default CustomMap;

