import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Modal, Checkbox, Button } from 'dpt-react';
import { FormattedMessage, Messages } from 'dpt-react/i18n';
import { closeModal } from 'dpt-react/utils';
import { useStores } from 'dpt-react/hooks';

import styles from '../../assets/styles/manageVessels.module.scss'

const Equipment = ({ inventory, equipment }) => {
  const { platformStore } = useStores();

  const toggleDeviceAccess = device => {
    const { selectedPlatform } = platformStore;
    selectedPlatform.toggleDeviceAccess(inventory.journal_id, device.device_id)
  };

  return useObserver(() => {
    const { selectedPlatform } = platformStore;
    const { devices } = equipment;

    return (
      <>
        <h2>
          { equipment.name}
        </h2>

        <ul>
          {
            devices.map(device => {
              const checked = selectedPlatform.hasDeviceAccess(inventory.journal_id, device.device_id);

              return (
                <li key={device.device_id} className={styles.device}>
                  <Checkbox checked={checked} handler={() => toggleDeviceAccess(device)}/>
                  <span>{device.name}</span>
                </li>
              )
            })
          }
        </ul> 
      </>
    )
  })
};

const EditDeviceAccessModal = () => {
  const { journalStore, platformStore } = useStores();

  const close = () => {
    closeModal('deviceAccessModal');
  };

  const onOpen = () => {
    const { selectedJournal } = journalStore;
    selectedJournal.getEquipmentInventory();
  };

  const updatePlatform = async () => {
    const { selectedPlatform } = platformStore;
    await selectedPlatform.updatePlatform();
    Messages.success('updated_platform');
    close();
  };

  return useObserver(() => {
    const { selectedJournal } = journalStore;
    const { selectedPlatform } = platformStore;
    const { equipment_inventory } = selectedJournal;
    const { equipment } = equipment_inventory;

    return (
      <Modal id="deviceAccessModal" onOpen={onOpen}>
        <div className="modal-header">
          <h1>
            <FormattedMessage id="allow_to_platform" values={{ platform: selectedPlatform.name}} />
          </h1>

          <i className="fas fa-times" onClick={close} />
        </div>
        <div className="modal-content">
          <ul className={styles.equipment}>
            {
              equipment.map(equipment => (
                <li key={equipment.equipment_id}>
                  <Equipment inventory={equipment_inventory} equipment={equipment} />
                </li>
              ))
            }
          </ul>
        </div>
        <div className="modal-footer">
          <Button text="update_access" onClick={updatePlatform}/>
          <p onClick={close}>
            <FormattedMessage id="cancel" />
          </p>
        </div>
      </Modal>
    )
  })
};

export default EditDeviceAccessModal;
