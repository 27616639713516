import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useRedirect, useStores } from "dpt-react/hooks";
import { Label, Spinner } from 'dpt-react'
import { FormattedMessage } from "dpt-react/i18n";
import no_results from '../../assets/images/icons/no_results.svg';

import styles from '../../assets/styles/journalDetails.module.scss'

const PlatformDeviceDetails = () => {
  const { id } = useParams();
  const { platformStore } = useStores();
  const { redirect } = useRedirect();

  useEffect(() => {
    const getData = async () => {
      await platformStore.getPlatformById(id);
      const { selectedPlatform } = platformStore;
      selectedPlatform.getDevicesFromPlaform();
    };

    getData();
  }, [])

  return useObserver(() => {
    const { selectedPlatform, loading } = platformStore;
    const { devices } = selectedPlatform;

    return (
      <main>
        <h1>
          <FormattedMessage id="devices_from_name" values={{ name: selectedPlatform?.name }} />
        </h1>

        {
          devices.length > 0 && !loading && (
            <h2>
              <FormattedMessage id="devices" />
            </h2>
          )
        }


        {
          devices.length === 0 && !loading && (
            <div className="placeholder">
              <div className="imgContainer">
                <img src={no_results} alt="no_results" />
              </div>
              <FormattedMessage id="there_are_no_devices_linked_to_this_platform" />
            </div>
          )
        }

        {
          loading && (
            <Spinner />
          )
        }

        <ul className={styles.list}>
          {devices.map((device) => (
            <li
              key={device.device_id}
              className={`collection-item ${styles.device}`}
              onClick={() => {
                redirect(`/journals/${device.journal_id}/devices/${device.device_id}`)
              }}
            >
              <div>
                <Label htmlFor="name" />
                {device.name}
              </div>

              <div>
                <Label htmlFor="type" />
                {device.type}
              </div>

              <div>
                <Label htmlFor="brand" />
                {device.brand}
              </div>

            </li>
          ))}
        </ul> 

      </main>
    );
  });
};

export default PlatformDeviceDetails;
