import React from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, Polyline, Pane } from 'react-leaflet'
import { FullscreenControl } from "react-leaflet-fullscreen";
import colorLogo from '../../assets/images/dbmatic_colors.svg';

import styles from '../../assets/styles/map.module.scss';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/dist/styles.css'
// import 'leaflet/dist/leaflet.fullscreen.css';
import { useObserver } from "mobx-react-lite";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});

const style = {
  width: "100%",
  height: "600px"
};

const JournalMap = ({ journals }) => {

  return useObserver(() => {
    const center = [53.06545639038086, 2.4597198963165283]

    return (
      <div className={styles.map}>
        <MapContainer style={style} center={center} zoom={4} maxZoom="19">

          <TileLayer
            attribution="open"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
          />

          <TileLayer
            attribution="open"
            url = "http://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" /> 

          {
            journals.map(journal => {
              const positions = journal.positions.map(measurement => {
                return {
                  latitude: measurement.value.position.latitude,
                  longitude: measurement.value.position.longitude,
                }
              });

              let latestPosition = undefined;
              if (journal.positions.length > 0) {
                latestPosition = [positions[0]?.latitude, positions[0]?.longitude];
              }


              return (
                <>
                  <Polyline positions={positions.map(position => [position.latitude, position.longitude])} attribution="open" color="darkorange"/>
                  {
                    latestPosition !== undefined && (
                      <Marker position={latestPosition}>
                        <Popup>
                          { `${ journal.vessel.name } - ${journal.vessel.hull_number}`}
                        </Popup>
                      </Marker>
                    )
                  }
                </>
              )
            })
          }

        <FullscreenControl />
        </MapContainer>
      </div>
    )
  })
}

export default JournalMap;

