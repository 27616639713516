import { decorate, observable } from "mobx";
import { Platform } from "../components/models/";

class PlatformStore {
  constructor() {
    this.platforms = [];
    this.newPlatform = new Platform();
    this.selectedPlatform = new Platform();
    this.loading = true;
    this.initalLoaded = false;
  }

  getAllPlatforms = async () => {
    this.platforms = await Platform.getAllPlatforms();
    this.loading = false;

    return this.platforms;
  };

  updatePlatform = (platform) => {
    return platform.updatePlatform();
  };

  getPlatformById = async (id) => {
    this.loading = true;
    this.selectedPlatform = await Platform.getPlatformById(id);
    this.loading = false;
  };

  addPlatform = async () => {
    const platform = await this.newPlatform.addPlatform();
    this.platforms.push(platform);
    this.newPlatform = new Platform();
  };

  deletePlatform = async (platform) => {
    await platform.deletePlatform();
    this.platforms = this.platforms.filter(
      (platformData) => platformData.platform_id !== platform.platform_id
    );
  };
}

decorate(PlatformStore, {
  platforms: observable,
  newPlatform: observable,
  selectedPlatform: observable,
  loading: observable,
  initalLoaded: observable,
});

export default new PlatformStore();
