import React from 'react';
import {useObserver} from 'mobx-react-lite';

const MobileAside = () => {
  return useObserver(() => {
    return (
      <aside>
        Mobile
      </aside>
    )
  })
};

export default MobileAside;
